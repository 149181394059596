export const openingCAModal = (state) => {
  return state.openingCAModal;
};

export const isSignatureRecorded = (state) => {
  return state.isSignatureRecorded;
};

export const isPassphraseModal = (state) => {
  return state.isPassphraseModal;
};

export const isKeyUploadModal = (state) => {
  return state.isKeyUploadModal;
};

export const showSignatureModal = (state) => {
  return state.showSignatureModal;
};

export const qesSigning = (state) => {
  return state.qesSigning;
};

export const digitalId = (state) => {
  return state.digitalId;
};

export const storageType = (state) => {
  return state.storageType;
};

export const createdCertificate = (state) => {
  return state.certificate;
};

export const createdOrganizationCertificate = (state) => {
  return state.organizationCertificate;
};

export const getPassphraseHint = (state) => {
  return state.passphrase_hint;
};

export const digitalIdCredential = (state) => {
  return state.digitalIdCredential;
};

export const savedDigitalId = (state) => {
  return state.savedDigitalId;
};

export const otp = (state) => {
  return state.otp;
};

// Access Recovery
export const digitalIdentity = (state) => {
  // ** This is retuning the verification type and not the Actual ID
  return state.digitalIdentity;
};
