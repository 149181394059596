export const token = (state) => {
	return state.token;
};

export const profile = (state) => {
	return state.profile;
};

export const loader = (state) => {
	return state.loader;
};

export const OTPFlag = (state) => {
	return state.OTPFlag;
};

export const documentOTP = (state) => {
	return state.documentOTP;
};

export const errors = (state) => {
	return state.errors;
};

