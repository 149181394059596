export const SET_CA_REGISTRATION_MODAL = (state, qes) => {
	state.openingCAModal = qes;
};

export const SET_SIGNATURE_RECORDED = (state, recordSign) => {
	state.isSignatureRecorded = recordSign;
};

export const SET_PASSPHRASE_MODAL = (state, keyModal) => {
	state.isPassphraseModal = keyModal;
};

export const SET_KEY_UPLOAD_MODAL = (state, keyModal) => {
	state.isKeyUploadModal = keyModal;
};

export const SET_SIGNATURE_MODAL = (state, modal) => {
	state.showSignatureModal = modal;
};

export const SET_CA_REGISTRATION_MODAL_SIGNING = (state, qes) => {
	state.qesSigning = qes;
};

export const SET_SAVED_DIGITAL_ID = (state, digitalId) => {
	state.savedDigitalId = digitalId;
};

export const SET_DIGITAL_ID = (state, id) => {
	state.digitalId = id;
};

export const SET_KEY_STORAGE_TYPE = (state, type) => {
	state.storageType = type;
};

export const SET_CERTIFICATE = (state, certificate) => {
	state.certificate = certificate;
};

export const SET_ORGANIZATION_CERTIFICATE = (state, certificate) => {
	state.organizationCertificate = certificate;
};

export const SET_PASSPHRASE_HINT = (state, hint) => {
	state.passphrase_hint = hint;
};

export const SET_DIGITAL_CREDENTIAL = (state, credential) => {
	state.digitalIdCredential = credential;
};

export const SEND_OTP_VIA_EMAIL = (state, email) => {
	state.otp = email;
};

// Access Recovery
export const SET_ID_TYPE_USED_FOR_VERIFICATION = (state, identity) => {
	state.digitalIdentity = identity;
};


