export default {
	token: null,
	token_type: null,
	profile: null,
	resendOTP: null,
	documentOTP: null,
	OTPFlag: {},
	loader: false,
	errors: [],
};

