import ApiTCA from './ApiToNoteCA';
import Api from './Api';

// const DOCUMENT_USER_SAVE_DIGITAL_ID = 'user-save-digital-id';
const DOCUMENT_USER_SAVE_DIGITAL_ID = 'user/data';
const SEARCH_FOR_DIGITAL_ID = 'digital-id/search';
const SEND_OTP_VIA_EMAIL = 'digital-id/otp/email';
const CREATE_CERTIFICATE_ASSISTED = 'signing/documents/certificate/create/assisted';
const CREATE_CERTIFICATE_MYSELF = 'signing/documents/certificate/create/myself';
const GET_KEY_STORAGE_TYPE = 'signing/key-storage-type';
const CREATE_CERTIFICATE_BASED_ON_ORGANIZATION_CREDENTIALS = 'signing/documents/certificate/generate';

// Access recovery
const GET_ID_TYPE_USED_FOR_VERIFICATION = 'digital-id/access-recovery/verification';
const VERIFY_OTP_FROM_SIGNATORY_EMAIL = 'digital-id/access-recovery/otp/email/verify';
const GET_OR_UPDATE_SIGNATORY_PROFILE_DETAILS = 'digital-id/access-recovery/profile';

export default {
	userSaveDigitalId(data) {
		return Api.put(`${DOCUMENT_USER_SAVE_DIGITAL_ID}`, data);
	},

	searchForDigitalId(data) {
		return ApiTCA.post(`${SEARCH_FOR_DIGITAL_ID}`, data);
	},

	getKeyStorageType(data) {
		return ApiTCA.get(`${GET_KEY_STORAGE_TYPE}`, { params: { identity: data } });
	},

	createCertificateAssisted(data) {
		return ApiTCA.post(`${CREATE_CERTIFICATE_ASSISTED}`, data);
	},

	createCertificateMyself(data) {
		return ApiTCA.post(`${CREATE_CERTIFICATE_MYSELF}`, data);
	},

	createCertificateBasedOnOrganizationCredentials(data) {
		return ApiTCA.post(`${CREATE_CERTIFICATE_BASED_ON_ORGANIZATION_CREDENTIALS}`, data);
	},

	sendOTP(data) {
		return ApiTCA.post(`${SEND_OTP_VIA_EMAIL}`, data);
	},

	// Access Recovery
	getDigitalIdentity(data) {
		return ApiTCA.get(`${GET_ID_TYPE_USED_FOR_VERIFICATION}`, { params: { identity: data } });
	},

	verifyOTPFromEmail(data) {
		return ApiTCA.post(`${VERIFY_OTP_FROM_SIGNATORY_EMAIL}`, data);
	},

	getSignatoryProfileDetails(data) {
		return ApiTCA.get(`${GET_OR_UPDATE_SIGNATORY_PROFILE_DETAILS}`, { params: { identity: data } });
	},
};

