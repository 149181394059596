export default {
	documents: [],
	documentAuditTrail: {},
	statistics: {},
	documentsByStatus: [],
	receivedDocuments: [],
	declinedDocuments: [],
	completedDocuments: [],
	document: [],
	initializedDocumentId: null,
	parentChildDocId: null,
	lockerDocs: [],
	lockerDoc: null,
	doneEditing: null,
	sharedDoc: null,
	toolData: null,
	resourceTools: [],
	isOpenModal: false,
	guest: { openGuestModal: false },
	confirmGuest: false,
	feedback: false,
	isDoneLoading: false,
	isDeclineLoading: false,
	isDownloading: false,
	message: null,
	cancel: false,
	status: 'new',
	isLoading: false,
	isDocLoading: false,
	isAllToolLoading: false,
	isToolLoading: false,
	croppedWidthHeight: null,
	participantError: false,
};

