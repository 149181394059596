import Certificate from '@/api/Certificate';
// import Tool from '@/api/Tool';
import store from '@/store';
import { useToast } from 'vue-toast-notification';
const toast = useToast();

export const clearCAStore = ({ commit }) => {
	commit('SET_DIGITAL_ID', null);
	commit('SET_DIGITAL_CREDENTIAL', null);
	commit('SET_SIGNATURE_MODAL', false);
	commit('SET_PASSPHRASE_MODAL', false);
	commit('SET_KEY_UPLOAD_MODAL', false);
	commit('SET_SIGNATURE_RECORDED', false);
};

export const closeAllQESSigningModal = ({ commit }, formData) => {
	commit('SET_PASSPHRASE_MODAL', formData);
	commit('SET_KEY_UPLOAD_MODAL', formData);
	commit('SET_CA_REGISTRATION_MODAL', formData);
};

export const signingWithQES = ({ commit, getters }, formData) => {
	const check = getters.digitalId;
	const hasIdentity = formData.credentials?.identity;

	const digitalIdExist = check?.exists || hasIdentity;

	const openCAVerificationModal = digitalIdExist && formData.open;

	if (openCAVerificationModal) {
		Certificate.getKeyStorageType(check?.identity)
			.then((response) => {
				if (response.data.key_storage_type == 'assisted') {
					commit('SET_PASSPHRASE_MODAL', true);
				} else {
					commit('SET_KEY_UPLOAD_MODAL', true);
				}
			})
			.catch((error) => {
				console.log('SET_KEY_STORAGE_TYPE', error);
				commit('SET_PASSPHRASE_MODAL', false);
				commit('SET_KEY_UPLOAD_MODAL', false);
			});
	} else {
		commit('SET_CA_REGISTRATION_MODAL', false);
	}
};

export const registerWithCA = ({ commit, getters }, formData) => {
	const check = getters.digitalId;
	const isDigitalIdAbsent = !check?.exists;

	const shouldOpenQesModal = isDigitalIdAbsent && formData.open;

	if (shouldOpenQesModal) {
		commit('SET_CA_REGISTRATION_MODAL', formData.open);
	}
};

export const accessRecoveryWithCA = ({ commit }, formData) => {
	const hasIdentity = formData.identity;

	if (hasIdentity) {
		commit('SET_CA_REGISTRATION_MODAL', true);
	}
};

export const createCertificate = ({ commit }, data) => {
	commit('SET_PASSPHRASE_HINT', null);
	if (data.mode == 'assisted') {
		Certificate.createCertificateAssisted(data.payload)
			.then((response) => {
				commit('SET_CERTIFICATE', response.data);
				commit('SET_PASSPHRASE_MODAL', false);
				commit('SET_SIGNATURE_RECORDED', true);
				commit('SET_SIGNATURE_MODAL', true);

				toast.success(response.data.message, {
					timeout: 5000,
					position: 'top-right',
				});
			})
			.catch((error) => {
				commit('SET_PASSPHRASE_HINT', error.response.data.passphrase_hint);
				commit('SET_SIGNATURE_RECORDED', false);

				toast.error(error.response.data.message, {
					timeout: 5000,
					position: 'top-right',
				});
			});
	} else {
		Certificate.createCertificateMyself(data.payload)
			.then((response) => {
				commit('SET_CERTIFICATE', response.data);
				commit('SET_KEY_UPLOAD_MODAL', false);
				commit('SET_SIGNATURE_RECORDED', true);
				commit('SET_SIGNATURE_MODAL', true);

				toast.success(response.data.message, {
					timeout: 5000,
					position: 'top-right',
				});
			})
			.catch((error) => {
				commit('SET_SIGNATURE_RECORDED', false);
				toast.error(error.response.data.message, {
					timeout: 5000,
					position: 'top-right',
				});
			});
	}
};

export const createOrganizationCertificate = ({ commit }, formData) => {
	Certificate.createCertificateBasedOnOrganizationCredentials(formData).then((response) => {
		console.log('SET_ORGANIZATION_CERTIFICATE', response.data);
		commit('SET_ORGANIZATION_CERTIFICATE', response.data);
	});
};

export const getDigitalId = ({ commit }, data) => {
	Certificate.searchForDigitalId(data)
		.then((response) => commit('SET_DIGITAL_ID', response.data))
		.catch((error) => {
			console.log('SET_DIGITAL_ID', error);
		});
};

export const getKeyStorage = ({ commit }, data) => {
	Certificate.getKeyStorageType(data)
		.then((response) => commit('SET_KEY_STORAGE_TYPE', response.data))
		.catch((error) => {
			console.log('SET_KEY_STORAGE_TYPE', error);
		});
};

export const storeDigitalId = ({ commit }, formData) => {
	commit('SET_DIGITAL_CREDENTIAL', formData);
	// const document = store.getters['document/userDocument'];
	const profile = store.getters['auth/profile'];
	const payload = { email: profile.email };

	Certificate.userSaveDigitalId(formData).then((response) => {
		commit('SET_SAVED_DIGITAL_ID', response.data);
	});

	Certificate.searchForDigitalId(payload)
		.then((response) => commit('SET_DIGITAL_ID', response.data))
		.catch((error) => {
			console.log('SET_DIGITAL_ID', error);
		});

		// Tool.all(document.id).then((response) => {
		// 	commit('SET_RESOURCE_TOOLS', response.data.data);
		// });
};

export const closeSignatureModal = ({ commit }) => commit('SET_SIGNATURE_MODAL', false);

export const closeBaseModal = ({ commit }, formData) => {
	commit('SET_PASSPHRASE_MODAL', formData);
	commit('SET_CA_REGISTRATION_MODAL', formData);
	commit('SET_SIGNATURE_MODAL', true);
};

export const sendOTPViaEmail = ({ commit }, data) => {
	Certificate.sendOTP(data)
		.then((response) => {
			commit('SEND_OTP_VIA_EMAIL', response.data);
			toast.success('Account created successfully', {
				timeout: 5000,
				position: 'top-right',
			});
		})
		.catch((error) => {
			console.log('SEND_OTP_VIA_EMAIL', error);
		});
};

// Access Recovery
export const getIdTypeUsedForVerification = ({ commit }, data) => {
	Certificate.getDigitalIdentity(data)
		.then((response) => commit('SET_ID_TYPE_USED_FOR_VERIFICATION', response.data))
		.catch((error) => {
			console.log('SET_ID_TYPE_USED_FOR_VERIFICATION', error);
		});
};



