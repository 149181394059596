import axios from 'axios';
import NProgress from 'nprogress';

const BASE_URL =
	process.env.NODE_ENV === 'development'
		? process.env.VUE_APP_CA_API_LIVE
		: 'https://dev-tonoteca.gettonote.com/api/v1/';

const BASE_TOKEN =
	process.env.NODE_ENV === 'development'
		? process.env.VUE_APP_CA_TOKEN
		: '2|lY2k6L4Ok9fsukvxWUDiY4RKbtArWtVx0Aig449ka5e46759';

const ApiTCA = axios.create({
	baseURL: BASE_URL,
});

const progressFns = () => {
	let progressTimeout,
		count = 0;

	const start = () => {
		count++;

		progressTimeout = setTimeout(() => {
			NProgress.start();
		}, 200);
	};

	const stop = () => {
		count = Math.max(0, count - 1);
		if (count > 0) return;

		NProgress.done();
		clearTimeout(progressTimeout);
	};

	return { start, stop };
};

const { start: progressStart, stop: progressStop } = progressFns();

ApiTCA.interceptors.request.use(
	async (config) => {
		let hasToken = await BASE_TOKEN;
		if (hasToken) config.headers['Authorization'] = `Bearer ${hasToken}`;
		if (!config.__noProgress) progressStart();

		config.headers['Content-Type'] = 'multipart/form-data';

		return config;
	},
	(error) => {
		progressStop();
		return Promise.reject(error);
	}
);

ApiTCA.interceptors.response.use(
	(response) => {
		if (!response.config.__noProgress) progressStop();
		return response;
	},
	(error) => {
		progressStop();

		console.log(error.response.statusText);
		return Promise.reject(error);
	}
);

export default ApiTCA;

