export default {
	isSignatureRecorded: false,
	openingCAModal: false,
	isPassphraseModal: false,
	isKeyUploadModal: false,
	showSignatureModal: false,
	qesSigning: null,
	digitalId: null,
	storageType: null,
	certificate: null,
	organizationCertificate: null,
	passphrase_hint: null,
	digitalIdCredential: null,
	savedDigitalId: null,
	otp: null,
	digitalIdentity: null,
};


